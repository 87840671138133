import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import H1 from "../components/h1"
import Button from "../components/button"
import ContentPage from "../layouts/contentPage"
import ContentPageWrapper from "../layouts/contentPageWrapper"
import ContentPageBg from "../layouts/contentPageBg"
import Logotype from "../images/logotype.svg";
import AnimatedBubble from "../components/animatedBubble"

const About = () => (
  <Layout>
    <Seo title="O marce" />
    <ContentPage>
      <ContentPageWrapper>
        <Logotype className="logotype"/> 
        <H1 className="subtitle uppercase">O<sub>2</sub>ddech dla Twojej skóry</H1>
          <div className="row">
            <div className="col-6">
                <h2 className="header-content">
                <strong>Kosmetyki Oxygenetic są odpowiedzią na zmieniające się środowisko oraz tempo życia.</strong>
                </h2>
                <AnimatedBubble />
            </div>
            <div className="col-6">
            <p>Fundamentem marki Oxygenetic jest rewolucyjna technologia polegająca na połączeniu 

składników działających na trzech, wzajemnie uzupełniających się płaszczyznach.  

Kompleks minerałów dotlenia komórki poprzez stymulację metabolizmu komórkowego,  

synbiotyk chroni komórki odbudowując mikrobiom skóry, a witaminy młodości C i A  

dostarczają jej niezbędnych składników odżywczych. Tylko prawidłowo przygotowana skóra,  

może dobrze wchłaniać składniki czynne. </p>
              <p>Marka Oxygenetic składa się z trzech linii idealnie dopasowanych do potrzeb skóry w każdym wieku. </p>
              <p><strong className="lime">Oxygenetic Energyze</strong> to energetyzująco-dotleniająca linia dermokosmetyków skierowana do kobiet pragnących zachować mocno nawilżoną, zregenerowaną i promienną skórę. </p>
              <p><strong className="magenta">Oxygenetic Relift</strong> to odmładzająco-dotleniająca linia dermokosmetyków stworzona z myślą o skórze dojrzałej,  

wymagającej rewitalizacji, uelastycznienia i wygładzenia.                                                                                                                                                                            

 </p>
              <p><strong className="blue">Oxygenetic Acid Peel </strong>
              to linia specjalistycznych peelingów kwasowych, które regulują odnowę komórkową skóry i usuwają martwy naskórek. Dzięki temu składniki aktywne przenikają głębiej, pozostawiając skórę gładką i promienną</p>

            </div>
          </div>
          <Button as={Link} to="/" primary="true" className="back">Strona główna</Button>
      </ContentPageWrapper>
      <ContentPageBg>
        <StaticImage
            src="../images/media/about/about-bg.png"
            loading="eager"
            width={552}
            quality={95}
            placeholder="blurred"
            formats={["auto", "webp", "avif"]}
            alt="oxygenetic"
        />
      </ContentPageBg>
    </ContentPage> 
  </Layout>
)

export default About
